import React from "react";
import "../styles/detail/detail.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const BlockOverview = ({ data }) => {
  return (
    <div className="rich-title container mg-tp">
        <div className="overview__row"> 
            <div className="overview__left">
                <h2>{data.title}</h2>
                <div className="rich-text-component"
                dangerouslySetInnerHTML={{
                    __html: data.describe.data?.childrenMarkdownRemark[0].html,
                }}
            />
            </div>
            <div className="overview__right"><GatsbyImage image={data.image?.localFile?.childrenImageSharp[0].gatsbyImageData} alt={data.image?.alternativeText}/></div>
        </div>
    </div>
  );
};

export default BlockOverview;
