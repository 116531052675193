import React from "react";
import { GatsbyImage} from "gatsby-plugin-image";
const BlockFeatureBlock = ({data}) =>{
    return(
        <div className="container mg-tp">
            <div className="inner__featureblock ">
            <div><GatsbyImage image={data.image?.localFile?.childImageSharp?.gatsbyImageData}  alt={data.image?.alternativeText}/></div>
            <div>
                <h4>{data.title}</h4>
                <ul className="inner__featureblock-icon">
                    {data.FeatureBlock.map((item,key)=>(
                        <li key={key}>
                            <div className="inner__featureblock-desc">
                                <img src={item.image?.localFile?.url} alt={data.image?.alternativeText}/>
                                <p>{item.title}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        </div>
    )
}
export default BlockFeatureBlock