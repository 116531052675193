import React from "react";
import { graphql } from "gatsby";
import BlockRichText from "./block-rich-text";
import BlockMedia from "./block-media";
import BlockQuote from "./block-quote";
import BlockSlider from "./block-slider";
import BlockIcon from "./block-icon";
import BlockFeatureBlock from "./block-FeatureBlock";
import BlockAsideImageWithText from "./block-AsideImageWithText"
import BlockAsideVideoWithText from "./block-AsideVideoWithText";
import BlockGround from "./block-ground";
import "../styles/detail/detail.css";
import BlockScroll from "../components/blockScroll";
import BlockOverview from "../components/block-overview";
import BlockOverviewLeft from "../components/block-overviewleft";
import BlockFrom from "../components/block-from";
import BlockNumberList from "./block-numberList";
import BlockPupop from "../components/homepage/popup";
import BlockLibary from "../components/block-library"
const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
  STRAPI__COMPONENT_SHARED_BLOCK_LIST_ICON: BlockIcon,
  STRAPI__COMPONENT_SHARED_FEATURE_BLOCK: BlockFeatureBlock,
  STRAPI__COMPONENT_SHARED_ASIDE_IMAGE_WITH_TEXT: BlockAsideImageWithText,
  STRAPI__COMPONENT_SHARED_ASIDE_VIDEO_WITH_TEXT: BlockAsideVideoWithText,
  STRAPI__COMPONENT_SHARED_PROJECT_GROUND: BlockGround,
  STRAPI__COMPONENT_SHARED_SCROLL:BlockScroll,
  STRAPI__COMPONENT_SHARED_OVERVIEW_TITLE:BlockOverview,
  STRAPI__COMPONENT_SHARED_OVERVIEW_TITLE_LEFT: BlockOverviewLeft,
  STRAPI__COMPONENT_SHARED_FROM_REGISTER: BlockFrom,
  STRAPI__COMPONENT_SHARED_NUMBER_LIST_ICON: BlockNumberList,
  STRAPI__COMPONENT_SHARED_LIBRARY: BlockLibary
};
const Block = ({ block }) => {
  const Component = componentsMap[block.__typename];
  if (!Component) {
    return null;
  }
  return <Component data={block} />;
};
const BlocksRendererProject = ({ blocks }) => {
  return (
    <div className="inner__detail ">
      {blocks.map((block, index) => (
          <Block key={index} prop={`${index}${block.__typename}`} block={block} />
      ))}
    </div>
  );
};
export const query = graphql`
  fragment Blockss on STRAPI__COMPONENT_SHARED_ASIDE_IMAGE_WITH_TEXTSTRAPI__COMPONENT_SHARED_ASIDE_VIDEO_WITH_TEXTSTRAPI__COMPONENT_SHARED_BLOCK_LIST_ICONSTRAPI__COMPONENT_SHARED_FEATURE_BLOCKSTRAPI__COMPONENT_SHARED_FROM_REGISTERSTRAPI__COMPONENT_SHARED_LIBRARYSTRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_NUMBER_LIST_ICONSTRAPI__COMPONENT_SHARED_OVERVIEW_TITLESTRAPI__COMPONENT_SHARED_OVERVIEW_TITLE_LEFTSTRAPI__COMPONENT_SHARED_PROJECT_GROUNDSTRAPI__COMPONENT_SHARED_QUOTESTRAPI__COMPONENT_SHARED_RICH_TEXTSTRAPI__COMPONENT_SHARED_SCROLLSTRAPI__COMPONENT_SHARED_SLIDERUnion{
    __typename
    
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_MEDIA {
      id
    }
    ... on STRAPI__COMPONENT_SHARED_NUMBER_LIST_ICON {
      id
      title
      numberlist {
        title
        description
        icon {
          alternativeText
          localFile {
            id
            url
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_QUOTE {
      title
      quoteBody: body
    }
    ... on STRAPI__COMPONENT_SHARED_SLIDER {
      title
      files {
        id
        mime
        alternativeText
        localFile {
          id
          url
          childImageSharp {
            gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_BLOCK_LIST_ICON {
        BlockListIcon {
          description
          title
          id
          link
          image {
            alternativeText
            localFile {
              url
              id
            }
          }
        }
    }
    ... on STRAPI__COMPONENT_SHARED_FEATURE_BLOCK {
        FeatureBlock {
          title
          id
          image {
            alternativeText
            localFile {
              id
              url
            }
          }
        }
        id
        description
        title
        link
        image {
          alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
              }
            }
          }
    }
    ... on STRAPI__COMPONENT_SHARED_FROM_REGISTER {
      describe{
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      id
      title
      project
    }
    ... on STRAPI__COMPONENT_SHARED_LIBRARY {
      id
      title
      file{
          alternativeText
          localFile {
            url
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
    }
    ... on STRAPI__COMPONENT_SHARED_ASIDE_IMAGE_WITH_TEXT {
        id
        title
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
            }
          }
        }
        link
        description
        AsideImageWithText {
          id
          title
          image {
            alternativeText
            localFile {
              id
              url
            }
          }
        }
    }
    ... on STRAPI__COMPONENT_SHARED_ASIDE_VIDEO_WITH_TEXT {
        id
        title
        description
        number {
          image {
            id
            alternativeText
            localFile {
              id
              url
            }
          }
          title
          link
          description
        }
        media {
          id
          mime
          alternativeText
          localFile {
            url
            id
            extension
          }
        }
    }
    ... on STRAPI__COMPONENT_SHARED_PROJECT_GROUND {
        ProjectGround {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
              }
            }
          }
          title
          id
          description
        }
        title
    }
    ... on STRAPI__COMPONENT_SHARED_SCROLL {
      id
      title
      key
    }
    
    ... on STRAPI__COMPONENT_SHARED_OVERVIEW_TITLE_LEFT {
      id
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
          }
        }
      }
      title
      describe {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_OVERVIEW_TITLE {
      id
      title
      image {
        alternativeText
        localFile {
          childrenImageSharp {
            gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
          }
        }
      }
      describe {
        data {
          childrenMarkdownRemark {
            html
          }
        }
      }
    }
    
  }
`;

export default BlocksRendererProject;
