import React,{useState,useEffect,Children} from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {FaBars, FaTimes} from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Layout from "../components/layout";
import Cover from "images/icon/arrow-right.svg";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavMenuProject,
  NavIcon,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
}from "../components/nav/NavbarElements"
import BlocksRendererProject from "../components/block-rendererProject"
import "../styles/page/project.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import BlockProject from "../components/block-project";
import BlockIcon from "../components/homepage/block-icon";
import iconclose from "images/icon/times.svg";
import iconangleup from "images/icon/angle-up.svg";
import iconlogo from "images/alphaland_logo.svg";
import { Formik, Field, Form } from "formik";
const ProjectPage = ({ data,children }) => {
  const project = data.strapiProject;
  const projectFrom = data.strapiProject.projectId
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)
  const [fix, setFix] = useState(false)
  const [popupForm, setPopupForm] = useState(false)
  const [popupAlter, setPopupAlter] = useState(false)
  const [nameFrom, setNameFrom] = useState("");
  const [phoneFrom, setPhoneFrom] = useState("");
  const projectSlug = project.slug
  const projectID = project.id
  function setFixed () {
    if(window.scrollY >= 500){
      setFix(true)      
    }else{
      setFix(false)
      setPopupForm(false)
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setPopupForm(true)
    }, "150000000")
  }, []);
  if (typeof window !== `undefined`){
    window.addEventListener("scroll", setFixed) 
  }
  const navmenu = project.blocks.filter(item => item.__typename === "STRAPI__COMPONENT_SHARED_SCROLL")
  const titleFrom = project.blocks.filter(item => item.__typename === "STRAPI__COMPONENT_SHARED_FROM_REGISTER")
  const onClickPupop = () =>{
    if(nameFrom == "" ){
    }else{
        if(phoneFrom == "" && phoneFrom.length == 10){
        }else{ 
          setPopupForm(true) 
        }
    }
    
}
  return (
    <Layout as="project">
      <GatsbySeo 
        title={project.title}
        openGraph={{
          url: `https://www.alphaland.com.vn/du-an/${project.slug}`,
          title: project.title,
          description: project.description,
          images: [
            {
              url: project.cover.localFile.url,
              alt: project.title,
            }
          ],
        }}
      />
      <GatsbyImage
            image={getImage(project?.cover?.localFile)}
            alt={project.cover?.alternativeText}
            className="detail__img-header"
      />
      <div  className={fix ? 'fixed' : 'nav__row'} >
      {/* {popupAlter && 
          <>
            <div onClick={() =>{setPopupForm(false)}} className="popup__fix"></div>
            <div className="popup">
                <div className="close__img" onClick={() =>{setPopupAlter(false)}}><GatsbyImage imgStyle={{padding: "5px 0",objectFit:"contain",display:"inline-flex",width:"285px",textAlign:"center",margin:"0 auto"}} className="nav__project-menu icon__close" image={project.logo?.localFile?.childImageSharp.gatsbyImageData} alt={project.logo?.alternativeText}/></div>
                <img className="image__logo-close" src={iconlogo}/>
                <div className="home__siteTitle">
                <div className="popup__recruitmentBlock">
                <div className="pupop__row title__recruit">
                    <h2 className="mgbt pdt">Cảm ơn anh/chị đã để lại thông tin.</h2>
                    <div>
                       Chuyên viên tư vấn sẽ liên hệ hỗ trợ anh chị trong thời gian sớm nhất!
                    </div>
                </div>
                </div>
                </div>
            </div>
          </>
      } */}
      {/* {popupForm && 
        <>
          <div onClick={() =>{setPopupForm(false)}} className="popup__fix"> </div>
          <div className="popup">
                    <div className="close__img" onClick={() =>{setPopupForm(false)}}><img src={iconclose} className="icon__close"/></div>
                    <GatsbyImage imgStyle={{padding: "5px 0",objectFit:"contain",display:"inline-flex",width:"285px",textAlign:"center",margin:"0 auto"}} className="nav__project-menu icon__close" image={project.logo?.localFile?.childImageSharp.gatsbyImageData} alt={project.logo?.alternativeText}/>
                    <div className="home__siteTitle-popup">
                    <div className="popup__recruitmentBlock">
                    <div className="pupop__row">
                      <h2>{titleFrom[0].title}</h2>
                      <div>{titleFrom[0].description}</div>
                       <Formik
                            initialValues={{ project: data.project}}
                            onSubmit={async (values) => {
                            let data = {
                                'entry.678634837': nameFrom,
                                'entry.344178928': phoneFrom,
                                'entry.545121623': projectFrom
                            }
                            await new Promise((resolve) => setTimeout(resolve, 500));
                                let queryString = new URLSearchParams(data);
                                queryString = queryString.toString();
                                let xhr = new XMLHttpRequest();
                                xhr.open('POST', 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfiLFI4iyP8LE0ePxSULb0mUTg0d4LwDROiKjmZelFj2qUclw/formResponse', true);
                                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                                xhr.send(queryString)
                                setNameFrom("")
                                setPhoneFrom("")
                                setPopupForm(false)
                                setPopupAlter(true) 
                            }}
                        >
                            <Form className="from__row">
                                <Field name="name" placeholder="Họ và tên" type="text" value={nameFrom} onChange={(e) => setNameFrom(e.target.value)} required/>
                                <Field name="phone" placeholder="Số Điện Thoại"  type="text" value={phoneFrom} onChange={(e) => setPhoneFrom(e.target.value)} required/>
                                <button type="submit" onClick={(onClickPupop)} className="button">Gửi</button>
                            </Form>
                        </Formik>
                        
                    </div>
                    </div>
                    </div>
                 </div>
        </> 
      } */}
      <IconContext.Provider  value={{color: "#4A4A4A"}}>
      <Nav className="nav"  click={click}>
          <NavbarContainer className=" container">
            <div className="icon_logo-navproject" to="/" onClick={closeMobileMenu}>
              <Link to="/"><GatsbyImage imgStyle={{padding: "5px 0",objectFit:"contain",display:"inline-flex"}} className="nav__project-menu" image={project.logo?.localFile?.childImageSharp.gatsbyImageData} alt={project.logo?.alternativeText}/></Link>
            </div>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes/> : <FaBars/>}
            </MobileIcon>
            <NavMenuProject className="nav__menu nav__menu-row" onClick={handleClick} click={click}>
              <li>
                {navmenu.map((item,index) => (
                    <a className="nav__project-hreft" key={index}  href={`#${item.key}`} >
                      <p className="font-bold text-neutral-700">{item?.title}</p>
                    </a>
                ))} 
              </li>
            </NavMenuProject>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
      </div>
      <main className="project__title-heading">
        <BlocksRendererProject blocks={project.blocks || []} />
      </main>
      <div className="container home__siteTitle home__siteTitle-top">
          <BlockProject Slug={projectSlug} projectdata={project}/>
       </div>
       <div>
         <BlockIcon/>
       </div>
       {/* {fix && 
        <a  href="#top">
          <div className="angleup">
              <img src={iconangleup} alt="angle-up" className="icon__angleup" />
          </div>
        </a>
       } */}
    </Layout>
  );
};
export const pageQuery = graphql`
  query ($slug: String) {
    strapiProject(slug: { eq: $slug } ) {
      slug
      title
      description
      projectId
      strapi_id
      id
      blocks {
        ...Blockss
      }
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80
              webpOptions: {quality: 80}
              placeholder: BLURRED)
          }
        }
      }
      relatedProject {
        id
        projects {
          title
          id
          description
          slug
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
                id
              }
            }
          }
        }
      }
      relatedArticle {
        articles {
          id
          title
          description
          slug
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
                id
              }
            }
          }
        }
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData(quality: 80
              webpOptions: {quality: 80}
              placeholder: BLURRED)
          }
        }
      }

    }
  }
`;
export default ProjectPage;

