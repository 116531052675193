import React from "react";
import "../styles/detail/detail.css"
const BlockNumberList = ({ data }) => {
  return (
    <div className=" container mg-tp">
        <div className="listnumber">
            <ul>
                {data.numberlist.map((project, id) => (
                    <li key={id}>
                        <div className="listnumber__row">
                            <h3>{project.title}</h3>
                            <img src={project.icon?.localFile?.url} alt="icon"/>
                        </div>
                        <div className="listnumber__desc">
                            <p>{project.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </div>
  );
};

export default BlockNumberList;
