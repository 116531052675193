import React, { useEffect,useState } from "react";
import "../styles/detail/detail.css"
import Cover from "images/icon/arrow-right.svg";
import { Formik, Field, Form } from "formik";
import iconclose from "images/icon/times.svg";
import iconlogo from "images/alphaland_logo.svg";
import "../styles/detail/popup.css"
import { background } from "@chakra-ui/react";
const BlockFrom = ({ data }) => {
    const projectFrom = data.project
    const [nameFrom, setNameFrom] = useState("");
    const [phoneFrom, setPhoneFrom] = useState("");
    const [popup, setPopup] = useState(false);
    const onClickPupop = () =>{
        // Google Ads Conversion code
        // typeof window !== "undefined" && window.gtag("event", "conversion", { "send_to":"AW-10929593374/yd8mCJjgxMUDEJ640dso" })
        if(nameFrom == "" ){
        }else{
            if(phoneFrom == ""){
            }else{ 
            setPopup(true) 
            }
        }
    }
  return (
    <div className="rich-title ">
         <div className="project__from">
            <div className="container">
                <h2>
                    {data.title}
                </h2>
                <div 
                    className="project__from-desc"
                    style={{backgroundColor:"initial"}}
                    dangerouslySetInnerHTML={{
                    __html: data.describe?.data?.childMarkdownRemark?.html,
                    }}
                />
                <Formik
                    initialValues={{ project: data.project}}
                    onSubmit={async (values) => {
                    let data = {
                        'entry.678634837': nameFrom,
                        'entry.344178928': phoneFrom,
                        'entry.545121623': projectFrom
                    }
                    await new Promise((resolve) => setTimeout(resolve, 500));
                        let queryString = new URLSearchParams(data);
                        queryString = queryString.toString();
                       
                        let xhr = new XMLHttpRequest();
                        xhr.open('POST', 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfiLFI4iyP8LE0ePxSULb0mUTg0d4LwDROiKjmZelFj2qUclw/formResponse', true);
                        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                        xhr.send(queryString)
                        setNameFrom("")
                        setPhoneFrom("")
                    }}
                >
                    <Form className="from">
                        <Field name="name" placeholder="Họ và tên" type="text" value={nameFrom} onChange={(e) => setNameFrom(e.target.value)} required/>
                        <Field name="phone" placeholder="Số Điện Thoại"  type="text" value={phoneFrom} onChange={(e) => setPhoneFrom(e.target.value)} required/>
                        <button type="submit" onClick={(onClickPupop)} className="button">Gửi<img src={Cover} className="icon_button" /></button>
                    </Form>
                </Formik>
                    {popup &&
                    <><div onClick={() =>{setPopup(false)}} className="popup__fix"></div>
                        <div className="popup">
                            <div className="close__img" onClick={() =>{setPopup(false)}}><img src={iconclose} className="icon__close"/></div>
                            <img className="image__logo-close" src={iconlogo}/>
                            <div className="home__siteTitle">
                            <div className="popup__recruitmentBlock">
                            <div className="pupop__row title__recruit">
                                <h2 className="pdt">Cảm ơn anh/chị đã để lại thông tin</h2>
                                <div>
                                    Chuyên viên tư vấn sẽ liên hệ hỗ trợ anh chị trong thời gian sớm nhất!
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                    </>   
                    }
                </div>
            </div>
    </div>
  );
};

export default BlockFrom;
