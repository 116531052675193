import React,{useState}from "react";
import { GatsbyImage} from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/detail/popup.css";
import FsLightbox from 'fslightbox-react';
const BlockSlider = ({data}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [slide, setSlide] = useState(0)
  const listImgs = data.file.map(item => item.localFile.url)
  const showSlide = slide => {
    setIsVisible(!isVisible)
    setSlide(slide)
  };
  return (
      <div className="container slider__project-detail">  
        <h2 className="slider-title">{data.title}</h2>
          <div className="library">
            {data.file?.map((file,key) => (
                <div className="library__row hover15 column" key={key}>
                    <button onClick={() => showSlide(key+1)}>
                        <figure>
                            <GatsbyImage
                              imgStyle={{transition: "0.3s ease-in-out 0s"}}
                              image={file.localFile.childImageSharp.gatsbyImageData}
                              alt={file.alternativeText}
                            />
                        </figure>
                    </button>
                </div>
            ))}
          </div>
          <FsLightbox
           toggler={isVisible}
           slide={slide}
           sources={listImgs}
            disableLocalStorage={true}
            loadOnlyCurrentSource={true}
          />
    </div>
  );
};

export default BlockSlider;
