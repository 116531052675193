import React from "react";
import "../styles/detail/detail.css"
const BlockIcon = ({ data }) =>{
  return (
    <div className="container mg-tp">
       <div className="detail__icon ">
          <ul> 
          {data.BlockListIcon.map((item, key) => (
                <li key={key}>
                  <img src={item.image?.localFile?.url} alt={item.image?.alternativeText} className="detail__icon-img" />
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </li>
              ))}
          </ul>
      </div>
    </div>
  )
}

export default BlockIcon