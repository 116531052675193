import React, { useState,useEffect } from 'react'
import { GatsbyImage} from "gatsby-plugin-image";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Slider from "react-slick";
import "../styles/detail/popup.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const  BlockGround = ({data}) => {
    const [selectedIndexCategory, setSelectedIndexCategory] = useState(0)
    const categories = data.ProjectGround;
    const changeCategory = (index) => setSelectedIndexCategory(index)
    return (
        <div className="container mg-tp">
            <div className="grounds">
                <h2>{data.title}</h2>
                <Tabs variant="soft-rounded" colorScheme="green">
                <TabList
                    sx={{ justifyContent: "center" }}
                    className="about__strategy__tablist"
                >
                    {categories.map((item,index)=>(
                            <Tab
                            key={index}
                            sx={{
                                color: "#B2B2B2",
                                borderRadius: "4px",
                                borderWidth: "1px",
                                borderColor: "#B2B2B2",
                                boxShadow: "1px",
                                textTransform:"uppercase",
                                fontSize:"12px",
                                margin:"10px",
                                // marginTop:"30px",
                                marginBottom:"15px",
                                padding:"8px 15px"
                            }}
                            _selected={{
                                color: "white",
                                bg: "#f58420",
                                borderRadius: "4px",
                                borderColor: "#f58420",
                            }}
                            _focus={{ boxShadow: "none" }}
                            onClick={() => changeCategory(index)}
                            >
                                <p className="grounds__button" >{item.title}</p>
                            </Tab> 
                    ))}
                </TabList>
                    
                </Tabs>
                    {(categories[selectedIndexCategory].image?.length > 1) && (
                        <Slider
                            dots={true}
                            infinite={true}
                            speed={300}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={true}
                            swipe={true}
                            autoplay={true}
                            autoplaySpeed={2000}
                        >
                            {categories[selectedIndexCategory].image.map((item1,key)=>(
                                <GatsbyImage className='ground__detail' key={key} image={item1.localFile.childImageSharp.gatsbyImageData} alt={item1.alternativeText}  objectFit="contain"/>
                            ))}
                        </Slider>
                    )}
                    {(categories[selectedIndexCategory].image?.length === 1) && (
                        <GatsbyImage className='ground__detail' image={categories[selectedIndexCategory].image[0].localFile.childImageSharp.gatsbyImageData} alt={categories[selectedIndexCategory].image[0].alternativeText}  objectFit="contain"/>
                    )}
            </div>
            
        </div>
    )
}

export default BlockGround