import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
const BlockAsideImageWithText = ({data}) =>{
    return(
       <div className="container mg-tp">
            <div className="inner__asideimage ">
            <div className="inner__asideimage-left">
                <h4>{data?.title}</h4>
                <ul>
                    {data.AsideImageWithText.map((item,id)=>(
                        <li key={id}>
                            <div className="inner__asideimage-fex">
                                <img src={item.image?.localFile.url} alt={item.image?.alternativeText}/>
                                <span>{item.title}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <p>{data?.description}</p>
            </div>
           <div className="inner__asideimage-right">
                <GatsbyImage image={data.image?.localFile?.childImageSharp.gatsbyImageData}  alt={data.image?.alternativeText} />
           </div>
        </div>
       </div>
    )
}
export default BlockAsideImageWithText