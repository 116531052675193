import React from "react";

const BlockAsideVideoWithText = ({data}) =>{
    const isVideo = data.media?.mime.startsWith("video");
    return(
       <div className="container mg-tp">
           <div className="inner__asidevideo">
               <div className="inner__asidevideo-left">
                    <h4>{data?.title}</h4>
                    <p className="inner__asidevideo-desc">{data?.description}</p>
                    <ul>
                        {data.number.map((item,key)=>(
                            <li key={key}>
                                <div className="inner__asidevideo-fex">
                                        <div className="inner__asidevideo-fexs">
                                            <span className="inner__asidevideo-desc1">{item.title}</span>
                                            <img className="inner__asidevideo-img" src={item.image?.localFile.url} alt="image"/>
                                        </div>
                                        <span className="inner__asidevideo-desc2">{item.description}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
               </div>
                <div className="inner__asidevideo-right">
                    {isVideo ? (
                        <div className="video-player">
                        <video controls>
                            <source src={data.media.localFile.url} type={`video/${data.media.localFile.extension}`} />
                            Your browser does not support the video tag.video/mp4
                        </video>
                        </div>
                    ) : (
                        <GatsbyImage
                        image={getImage(data.file?.localFile)}
                        alt={data.file?.alternativeText}
                        />
                    )}
                </div>
            </div>
            
       </div>
    )
}
export default BlockAsideVideoWithText